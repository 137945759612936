import { onCleanup } from "solid-js";
import { isServer } from "solid-js/web";

export function onClick(el: HTMLElement, fn: () => (e: Event) => void) {
	let _pointerDownTime = 0;
	const onPointerDown = (e: PointerEvent) => {
		// pointerDownTime = Date.now();
		e.stopPropagation();
		if (e.pointerType === "mouse" && e.button === 0) {
			el.removeEventListener("click", onClick);
			const reAddClick = () => {
				requestAnimationFrame(() => {
					el.addEventListener("click", onClick);
				});
			};
			el.addEventListener("pointerup", reAddClick, { once: true });
			el.addEventListener("pointercancel", reAddClick, { once: true });
			el.addEventListener("pointerleave", reAddClick, { once: true });
			fn()(e);
		}
	};
	const onClick = (e: MouseEvent | TouchEvent) => {
		e.stopPropagation();
		fn()(e);
	};
	el.addEventListener("pointerdown", onPointerDown);
	el.addEventListener("click", onClick);
	onCleanup(() => {
		el.removeEventListener("pointerdown", onPointerDown);
		el.removeEventListener("click", onClick);
	});
}

if (!isServer) {
	//@ts-ignore
	window.onClick = onClick;
}

declare module "solid-js" {
	namespace JSX {
		interface Directives {
			onClick: (e: Event) => void;
		}
	}
}
