// @ts-ignore
import { isServer } from "solid-js/web";
import { onClick } from "./utils/on_click";
if (!isServer) {
	// @ts-ignore
	window.onClick = onClick;
}
import { clientOnly } from "@solidjs/start";
import "tippy.js/animations/shift-away.css";
import "tippy.js/dist/tippy.css";
import "~/global.css";

const InnerApp = clientOnly(() => import("./inner_app"));
// import InnerApp from "./inner_app";

export default () => {
	return <InnerApp />;
};
